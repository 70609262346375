import React, { useState, useEffect } from 'react';
import 'twin.macro';
import { GraphQLClient, gql } from 'graphql-request';
import { useStoreState, useStoreActions } from 'easy-peasy';
import CurrencyFormat from '../global/CurrencyFormat';
import { Container } from '../atoms';
import { EmptyState } from '../elements';
import CartItem from './CartItem';
import config from '../../utils/config';

const client = new GraphQLClient(config.apiUrl, {
  timeout: 60000,
});

const createOrderMutation = gql`
  mutation createOrder($input: OrderInput!) {
    createOrder(input: $input) {
      id
      paymentId
      paymentUrl
    }
  }
`;

const CartSteps = () => {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const cartItems = useStoreState((state) => state.cart.items);
  const removeFromCart = useStoreActions((actions) => actions.cart.remove);

  const calculateTotal = () => {
    let newTotal = 0;
    cartItems.forEach((item) => {
      newTotal += item.price * item.quantity;
    });
    if (total !== newTotal) {
      setTimeout(() => {
        setTotal(newTotal);
      }, 300);
    }
  };

  // run every time cart item updates
  useEffect(() => {
    if (cartItems && cartItems.length > 0) {
      calculateTotal();
    }
  }, [cartItems]);

  const handleCreatePayment = async () => {
    const items = cartItems.map((item) => {
      return {
        productId: item.id,
        quantity: item.quantity,
      };
    });
    const variables = {
      input: {
        products: items,
      },
    };
    setLoading(true);

    await client
      .request(createOrderMutation, variables)
      .then((data) => {
        setTimeout(() => {
          if (data) {
            window.location.href = data?.createOrder?.paymentUrl;
          }
        }, 400);
      })
      .catch((error) => {
        console.log('error', error);
      });
    setLoading(false);
  };

  if (cartItems.length === 0) {
    return <EmptyState title="No items in your cart." isShowShopButton />;
  }

  return (
    <Container>
      <div tw="bg-white">
        <div tw="max-w-2xl mx-auto pt-16 pb-24 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <h1 tw="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Shopping Cart
          </h1>
          <div tw="mt-12 lg:grid lg:grid-cols-12 lg:gap-x-12 lg:items-start xl:gap-x-16">
            <section aria-labelledby="cart-heading" tw="lg:col-span-7">
              <ul tw="border-t border-b border-gray-200 divide-y divide-gray-200">
                {cartItems.map((item, index) => (
                  <CartItem
                    key={item.itemId}
                    item={item}
                    removeFromCart={() => removeFromCart(index)}
                  />
                ))}
              </ul>
            </section>

            {/* Order summary */}
            <section
              aria-labelledby="summary-heading"
              tw="mt-16 bg-gray-50 rounded-lg px-4 py-6 sm:p-6 lg:p-8 lg:mt-0 lg:col-span-5"
            >
              <h2 id="summary-heading" tw="text-lg font-medium text-gray-900">
                Order summary
              </h2>

              <dl tw="mt-6 space-y-4">
                <div tw="flex items-center justify-between">
                  <dt tw="text-sm text-gray-600">Subtotal</dt>
                  <dd tw="text-sm font-medium text-gray-900">
                    <CurrencyFormat amount={total} />
                  </dd>
                </div>
                <div tw="border-t border-gray-200 pt-4 flex items-center justify-between">
                  <dt tw="flex items-center text-sm text-gray-600">
                    <p
                      href="/"
                      tw="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500"
                    >
                      <span tw="font-semibold text-sm ">Items</span>
                    </p>
                  </dt>
                  <dd tw="text-sm font-medium text-gray-900">
                    {cartItems?.length}
                  </dd>
                </div>

                <div tw="border-t border-gray-200 pt-4 flex items-center justify-between">
                  <dt tw="text-base font-medium text-gray-900">Order total</dt>
                  <dd tw="text-base font-medium text-gray-900">
                    <CurrencyFormat amount={total} />
                  </dd>
                </div>
              </dl>

              <div tw="mt-6">
                <button
                  onClick={handleCreatePayment}
                  type="button"
                  tw="bg-green font-semibold hover:bg-green-light py-3 text-sm text-white uppercase w-full"
                >
                  {loading ? 'Loading...' : 'Checkout'}
                </button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CartSteps;
