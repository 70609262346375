import React from 'react';
import 'twin.macro';
import { Link } from 'gatsby';
import CurrencyFormat from '../global/CurrencyFormat';

const CartItem = ({ item, removeFromCart }) => {
  return (
    <li key={item.id} tw="flex py-6 sm:py-10">
      {item.image && (
        <div tw="flex-shrink-0">
          <img
            src={item.image}
            alt={item.title}
            tw="w-24 h-24 rounded-md object-center object-cover sm:w-48 sm:h-48"
          />
        </div>
      )}

      <div tw="ml-4 flex-1 flex flex-col justify-between sm:ml-6">
        <div tw="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
          <div>
            <div tw="flex justify-between">
              <h3 tw="text-sm">
                <Link
                  to={`/product/${item.slug}`}
                  tw="font-semibold text-xl mb-3 text-black hover:text-gray-800"
                >
                  {item.title}
                </Link>
              </h3>
            </div>
            <div tw="mt-1 flex text-sm">
              <p tw="text-green-500">{item.brand}</p>
            </div>
            <p tw="mt-1 text-sm font-medium text-gray-900">
              <CurrencyFormat amount={item.price * item.quantity} />
            </p>
          </div>

          <div tw="mt-4 sm:mt-0 sm:pr-9">
            <p>
              Quantity:{'  '} {item.quantity}
            </p>
            <div tw="absolute top-0 right-0">
              <button
                type="button"
                onClick={removeFromCart}
                tw="-m-2 p-2 font-semibold inline-flex text-gray-400 hover:text-red-400"
              >
                <span tw="sr-only">Remove</span>
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default CartItem;
