import React from 'react';
import 'twin.macro';

import Layout from '../components/Layout';
import Seo from '../components/global/Seo';
import config from '../utils/config';
import CartSteps from '../components/cart/CartSteps';

const Cart = () => {
  return (
    <Layout>
      <Seo
        title="Cart"
        description={`Your order items at ${config.siteName}`}
        url={`${config.siteUrl}/cart`}
      />
      <CartSteps />
    </Layout>
  );
};

export default Cart;
